import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39c2fdd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, {
              slot: "end",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Select Pickup Location")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (!_ctx.isSearchingEnabled)
          ? (_openBlock(), _createBlock(_component_ion_accordion_group, { key: 0 }, {
              default: _withCtx(() => [
                (_ctx.shipGroup.shipmentMethodTypeId === 'STOREPICKUP')
                  ? (_openBlock(), _createBlock(_component_ion_accordion, {
                      key: 0,
                      value: "rejectedFacility"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.locationOutline
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("Showing pickup locations near")) + " " + _toDisplayString(_ctx.storePickupRejectedFacility.postalCode), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, {
                          slot: "content",
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.storePickupRejectedFacility.storeName) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.storePickupRejectedFacility.address1), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.storePickupRejectedFacility.address2), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.storePickupRejectedFacility.city) + _toDisplayString(_ctx.storePickupRejectedFacility.city && _ctx.storePickupRejectedFacility.state ? ", " : "") + _toDisplayString(_ctx.storePickupRejectedFacility.state) + " " + _toDisplayString(_ctx.storePickupRejectedFacility.postalCode), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              fill: "clear",
                              slot: "end",
                              onClick: _ctx.enableSearching
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("Edit Zip Code")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_ion_accordion, {
                      key: 1,
                      value: "rejectedFacility"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.locationOutline
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("Showing pickup locations near")) + " " + _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.postalCode), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, {
                          slot: "content",
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.shipGroup.shipTo.postalAddress.toName) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.address1), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.address2), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.city) + _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.city && _ctx.shipGroup.shipTo.postalAddress.stateCode ? ", " : "") + _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.stateCode) + " " + _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.country) + " " + _toDisplayString(_ctx.shipGroup.shipTo.postalAddress.postalCode), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              fill: "clear",
                              slot: "end",
                              onClick: _ctx.enableSearching
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("Edit Zip Code")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("form", {
              key: 1,
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.searchStores()), ["prevent"]))
            }, [
              _createVNode(_component_ion_searchbar, {
                placeholder: _ctx.$t('Search Zip Code'),
                modelValue: _ctx.queryString,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryString) = $event))
              }, null, 8, ["placeholder", "modelValue"]),
              _createVNode(_component_ion_button, {
                class: "ion-margin",
                type: "submit",
                expand: "block"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Find Stores")), 1)
                ]),
                _: 1
              })
            ], 32)),
        (_ctx.isLoadingStores)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_spinner, {
                    name: "crescent",
                    slot: "start"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("Fetching stores.")), 1)
                ]),
                _: 1
              })
            ]))
          : (_ctx.nearbyStores.length)
            ? (_openBlock(), _createBlock(_component_ion_list, { key: 3 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, {
                    lines: "full",
                    color: "light"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("Nearby Stores")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_radio_group, {
                    value: _ctx.selectedFacility?.facilityId,
                    onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updatePickupStore($event)))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nearbyStores, (store) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: store.facilityId
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_radio, {
                              value: store.facilityId,
                              "label-placement": "end"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(store.facilityName) + " ", 1),
                                    _createElementVNode("p", null, _toDisplayString(store.address1), 1),
                                    _createElementVNode("p", null, _toDisplayString(store.address2), 1),
                                    _createElementVNode("p", null, _toDisplayString(store.city) + _toDisplayString(store.city && store.state ? ", " : "") + _toDisplayString(store.state) + " " + _toDisplayString(store.postalCode), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                (store.distance)
                                  ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(store.distance) + " " + _toDisplayString(_ctx.$t("miles")), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }))
            : (_ctx.errorMessage)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 4,
                  lines: "none",
                  class: "ion-text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.errorMessage)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
        (_ctx.nearbyStores.length)
          ? (_openBlock(), _createBlock(_component_ion_fab, {
              key: 5,
              vertical: "bottom",
              horizontal: "end",
              slot: "fixed"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_fab_button, {
                  disabled: Object.keys(_ctx.selectedFacility).length == 0 || _ctx.selectedFacility?.facilityId == _ctx.shipGroupSelectedFacility?.facilityId,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateFacility()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}